.container {
    display: flex;
    justify-content: center;
    width: 85%;
    flex-direction: column;
    align-items: center;
}

.pieLabel {
    padding: 2rem;
    font-family: 'Oxygen';
    font-size: 20px;
    color: rgba(34, 34, 34, 0.9);
}

