.container {
    margin: 50px 0px;
    width: 80%;
}

.containerBottom {
    margin: 1rem;
    width: 100%;
}

.card { 
    margin: 0 2% !important;
}


.USemissions {
    border-bottom: 10px solid rgba( 63, 81, 181, 0.9);
}

.EuropeEmissions {
    border-bottom: 10px solid rgba(244, 65, 38, 0.9) ;
}

.WorldEmissions {
    border-bottom: 10px solid rgba(33, 148, 243, 0.9) ;
}