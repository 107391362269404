.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
}

.containerBottom {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-evenly;
    padding: 2rem;
}

@media screen and (max-width: 1000px) {
    .containerBottom {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: space-evenly;
        padding: 1rem;
        width: 80%;
    }
}

.lineChart {
    padding: 2rem;
}