
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
/*
body {
  background: linear-gradient(to bottom, #FFFFFF, #19191F);
}
*/
.test {
  color: white;
  font-size: 100;
}

.maintext {
  display: flex;
  align-items: left;
  flex-direction: column;
  font-family: 'Roboto';
  width: 60vw;
  height: 30vh;
  padding: 20px;
  position: relative;
  top: -10vw;
  left: -10vw;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}

.mobiletitletext {
  font-size: 64px;
  line-height: 64px;
}

.mobiledetailtext {
  font-size: 24px;
}

.mobilemaintext {
  display: flex;
  align-items: left;
  flex-direction: column;
  font-family: 'Roboto';
  width: 70vw;
  height: 70vh;
  padding: 20px;
  position: relative;
  top: -10vw;
  left: -10vw;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}

.titletext {
  font-size: 6vw;
  line-height: 6vw;
}

.detailtext {
  font-size: 3vw;
}


.section2 {
  width: 100vw;
  height: 50vh;
  display: flex;
  flex-direction: row;
  background-color: #EDEDF2;
  align-items: center;
  
}

.tools {
  font-size: 96px;
  font-family: 'Roboto';
  text-align: center;
  vertical-align: middle;
}


.icongrid {
  width: 50vw;
}

.iconimage {
  padding: 30px;
  max-width: 15vw;
  max-height: 15vh;
  min-width: 5vw;
  min-height: 5vh;
  
}

.bottombar {
  position: relative;
  bottom: 0px;
  width: 100vw;
  height: 100px;
  background-color: #EDEDF2;
  color: '#646478';
  background-color: rgba(255, 255, 255, .3);
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  display: flex;
  justify-content: center;
  align-items: center;
  

}





/*       -----------------------Blog Styling------------------        */

.project-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  padding: 2rem;

}


.project-title-text {
  display:flex;
  font-family: 'Oxygen';
  font-size: 55px;
  line-height: 55px;
  padding: 5rem;
  color: rgba(34, 34, 34, 0.9);
  justify-content: center;
  text-align: center;
}

.project-title-text-2 {
  display:flex;
  font-family: 'Oxygen';
  font-size: 30px;
  line-height: 30px;
  padding: 2rem;
  color: rgba(34, 34, 34, 0.9);
  justify-content: flex-start;
}

.project-title-text-3 {
  display:flex;
  font-family: 'Oxygen';
  font-size: 18px;
  line-height: 18px;
  padding: 2rem;
  color: rgba(34, 34, 34, 0.9);
  justify-content: flex-start;
}

.project-main-text {
  display:flex;
  font-family: 'Oxygen';
  font-size: 18px;
  padding: 2rem;
  color: rgba(34, 34, 34, 0.9);
  justify-content: center;
  width: 60%;
}

.project-secondary-text {
  display:flex;
  font-family: 'Oxygen';
  font-size: 18px;
  padding: 1rem;
  color: rgba(34, 34, 34, 0.9);
  justify-content: center;
  width: 60%;
}

.references {
  display: flex;
  width: 40%;
  padding: 1rem;
  justify-content: center;
}

.blog-pics{
  display:flex;
  font-family: 'Oxygen';
  font-size: 18px;
  line-height: 20px;
  padding: 2rem;
  color: rgba(34, 34, 34, 0.9);
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 60%;
}

.blog-row{
  display:flex;
  font-family: 'Oxygen';
  font-size: 18px;
  line-height: 20px;
  padding: 2rem;
  color: rgba(34, 34, 34, 0.9);
  justify-content: center;
  flex-direction: row;
  align-content: center;
  align-items: center;
  width: 60%;
}

/* ------------ End Blog Styles -------------- */






/*       ----------------------- Contact Styling------------------        */

.modalStyle{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 700px;
  height: 480px;
  transform: translate(-50%, -50%);
  padding: 50px;
  z-index: 1000;
  background: rgba(243, 243, 243, 0.38);
  box-sizing: 'border-box';
  box-shadow: 0px 1px 1px rgba(150, 150, 150, 0.25);
  backdrop-filter: blur(15px);
  border-radius: 30px;
  font-family: 'Roboto';
  color: #333333;
}

.modalOverlay{
  position: fixed;
  top: 50%; 
  left: 50%;
  width: 780px;
  height: 540px;
  transform: translate(-50%, -50%);
  padding: 100px;
  z-index: 1000;
  background: rgba(196, 196, 196, 0.31);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 30px;
}

.contacth1{
  font-size: 64px;
  line-height: 85px;
  padding-bottom: 30px;
  
}

.contacth2{
  font-size:  36px;
  line-height: 50px;
}

.contacth3{
  font-size: 24px;
  line-height: 30px;
}

.linkedImg{
  width: 100px;
  padding-left: 50px;
}

@media only screen and (min-width: 150px) and (max-width: 700px) {

  .project-main-text {
    display:flex;
    font-family: 'Oxygen';
    font-size: 18px;
    padding: 1rem;
    color: rgba(34, 34, 34, 0.9);
    justify-content: center;
    width: 100%;
  }

  .project-secondary-text {
    display:flex;
    font-family: 'Oxygen';
    font-size: 18px;
    padding: 1rem;
    color: rgba(34, 34, 34, 0.9);
    justify-content: center;
    width: 100%;
  }
  

  .blog-pics{
    display:flex;
    font-family: 'Oxygen';
    font-size: 18px;
    line-height: 20px;
    padding: 1rem;
    color: rgba(34, 34, 34, 0.9);
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100%;
  }

  .project-title-text {
    display:flex;
    font-family: 'Oxygen';
    font-size: 45px;
    line-height: 45px;
    padding: 1rem;
    color: rgba(34, 34, 34, 0.9);
    justify-content: center;
    text-align: center;
  }
  

  .contacth1{
    font-size: 32px;
    line-height: 45px;
    padding-bottom: 15px;
  }
  
  .contacth2{
    font-size:  18px;
    line-height: 25px;
  }
  
  .contacth3{
    font-size: 12px;
    line-height: 15px;
  }

  .linkedImg{
    width: 75px;
  }


  .modalStyle{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 280px;
    height: 350px;
    transform: translate(-50%, -50%);
    padding: 50px;
    z-index: 1000;
    background: rgba(243, 243, 243, 0.58);
    box-sizing: 'border-box';
    box-shadow: 0px 1px 1px rgba(150, 150, 150, 0.25);
    backdrop-filter: blur(15px);
    border-radius: 30px;
    font-family: 'Roboto';
    color: #333333;
  }
  
  .modalOverlay{
    position: fixed;
    top: 50%; 
    left: 50%;
    width: 300px;
    height: 370px;
    transform: translate(-50%, -50%);
    padding: 150px;
    z-index: 1000;
    background: rgba(196, 196, 196, 0.51);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 30px;
  }
  
  .tools {
    font-size: 64px;
    font-family: 'Roboto';
    text-align: center;
    vertical-align: middle;
  }


}


/*       ----------------------- End Contact Styling------------------        */